/* .sidebar .css-vt7cuc-MuiListItemText-root {
    color: #fff !important;
}

.sidebar .css-1gxhmaf-MuiListItemIcon-root {
    color: #fff !important;
} */

/* .sidebar {
    background: #202020;
} */

:root {
    --maincolor: #796621;
}

.color-white {
    color: white !important;
}

.css-1hhrl8g {
    display: none !important;
}

.css-103o8ot a,
.css-17u7xez-MuiStack-root a {
    text-decoration: none;
}

.nihdetail p {
    margin-bottom: 10px;
}

.nihtable tr {
    cursor: pointer !important;
}

/* .css-7k7ju5-MuiButtonBase-root-MuiCardActionArea-root {
    color: white !important;
} */

/* .bg-sidebar {
    background: rgb(32, 32, 32);
} */

table {
    font-family: Public Sans, sans-serif !important;
}

.css-9w714p {
    display: none !important;
}

/* color seting */
.css-26q4qm {
    max-width: 870px !important;
}

.datatable .ant-table-pagination {
    display: none !important;
}

.datatable .ant-badge-status-dot {
    width: 8px !important;
    height: 8px !important;
}

.chat-wrapper1 .ant-avatar {
    width: 50px !important;
    height: 47px !important;
}

.chat-wrapper1 .ant-avatar .ant-avatar-string {
    margin-top: 7px !important;
}

.chat-wrapper1 ol {
    margin-left: 35px;
}

.chat-wrapper1 ul {
    margin-left: 35px;
    margin-bottom: 20px;
}

/* .chat-wrapper1 h1 {
    font-size: 2.5rem;
}

.chat-wrapper1 h2 {
    font-size: 2rem;
}

.chat-wrapper1 h3 {
    font-size: 1.75rem;
}

.chat-wrapper1 h4 {
    font-size: 1.5rem;
}

.chat-wrapper1 h5 {
    font-size: 1.25rem;
} */

.chat-wrapper1 h1,
.chat-wrapper1 h2,
.chat-wrapper1 h3,
.chat-wrapper1 h4,
.chat-wrapper1 h5,
.chat-wrapper1 h6 {
    margin: 1.5rem 0 1rem 0;
    padding: 0;
    line-height: 1.2;
}

.chat-wrapper1 p {
    margin: 0 0 1rem 0;
    padding: 0;
}

.chat-wrapper1 a {
    margin: 0;
    padding: 0;
    text-decoration: none;
    color: inherit;
}

.chat-wrapper1 ul,
.chat-wrapper1 ol {
    margin: 0 0 1rem 1.5rem;
    padding: 0;
}

.chat-wrapper1 li {
    margin: 0.5rem 0;
    padding: 0;
}

.chat-wrapper1 blockquote {
    margin: 1.5rem 0;
    padding: 0.5rem 1rem;
    border-left: 4px solid #ccc;
    background-color: #f9f9f9;
}

.chat-wrapper1 pre,
.chat-wrapper1 code {
    margin: 1rem 0;
    padding: 1rem;
    background-color: #f4f4f4;
    border-radius: 4px;
    font-family: monospace;
}

.chat-wrapper1 hr {
    margin: 1.5rem 0;
    padding: 0;
    border: 0;
    border-top: 1px solid #ddd;
}

.chat-wrapper1 {
    scrollbar-width: thin;
    /* For Firefox */
    scrollbar-color: rgb(74, 74, 74) rgb(209, 209, 209);
    /* For Firefox */
}

.chat-wrapper1::-webkit-scrollbar {
    width: 12px;
    border-radius: 20px;
}

.chat-wrapper1::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 20px;
}

.chat-wrapper1::-webkit-scrollbar-thumb {
    background: #d5d5d5;
    border-radius: 20px;
}

.chat-wrapper1::-webkit-scrollbar-thumb:hover {
    background: #555;
    /* New color when hovering */
    cursor: pointer;
}

.chat-wrapper1 p {
    margin-bottom: .5rem;
    margin-top: 1.25em;
}

.ai-response ul {
    margin-left: 25px;
}

.table-scroll {
    width: 100%;
    overflow-x: scroll;

}

.totalcount {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji' !important;
    font-size: 14px;
}

.table-scroll::-webkit-scrollbar {
    height: 8px;
    transition: 0.5s;
}

::placeholder {
    color: #909090 !important;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    font-weight: 100;
}

.table-scroll::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(250, 250, 250, 0.3);
}

.table-scroll::-webkit-scrollbar-thumb {
    background: #fafafa46;
    background: #ffffff85;
    border-radius: 6px;
}

.table-scroll::-webkit-scrollbar-thumb:hover {
    background-color: #4d4c4b !important;
}

.ant-form-item-label label {
    color: #202020 !important;
    /* font-size: 15px !important; */
}

.toolbar {
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
}

@media screen and (max-width: 570px) {
    .toolbar {
        display: inline-block;
    }
}

.select-toolbar {
    display: flex;
}

.select-toolbar .item {
    min-width: 120px;
    margin-right: 20px;
    margin-bottom: 10px;
}

@media screen and (max-width: 660px) {
    .select-toolbar {
        display: block;
    }
}

.css-1ptmd3c {
    color: #fff !important;
}

/* Button */
.btn_cancel {
    background-color: #202020 !important;
}

.btn_cancel:hover {
    background-color: #414141 !important;
}

.btn_info {
    background-color: #478EF5 !important;
}

.btn_info:hover {
    background-color: #3280f5 !important;
}

.ant-input,
.ant-select-selector,
.ant-picker {
    border-radius: 3px !important;
}

.ant-upload:hover {
    /* border: 1px solid  rgba(145, 158, 171, 0.24) !important; */
    border-color: #38b6ff !important;
}

.ant-empty-description {
    color: #000;
}

.assets-value {
    color: #3280f5;
    font-style: italic;
}

.set_reminder {
    background-color: #009B00;
    color: #fff;
}

/* .ant-table td {
    font-size: 15px !important;
} */

p,
h1,
h2,
h3,
h4,
h5,
span,
div,
select,
input,
td,
tr,
th,
a,
.ant-input,
span {
    /* font-family: Arial, Helvetica, sans-serif !important; */
    /* font-family: sans-serif !important; */
    font-family: Public Sans, sans-serif !important;
}

.site-result-demo-error-icon {
    color: red;
}

.ant-popover {
    z-index: 99999 !important;
}

.actionmore {
    color: #424242;
    cursor: pointer
}

.actionmore:hover {
    color: #796621;
}

/* Post code CSS */
#lookup_field button {
    margin-top: 10px;
    margin-bottom: 10px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    background-color: transparent;
    outline: 0;
    border: 0;
    border-radius: 0;
    padding: 0;
    cursor: pointer;
    vertical-align: middle;
    text-decoration: none;
    color: inherit;
    font-weight: 100;
    line-height: 1.7142857142857142;
    font-size: 0.875rem;
    text-transform: capitalize;
    font-family: Public Sans, sans-serif;
    min-width: 64px;
    padding: 6px 16px;
    border-radius: 3px;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    color: #fff;
    background-color: #796621;
    box-shadow: 0px 3px 1px -2px rgb(145 158 171 / 20%), 0px 2px 2px 0px rgb(145 158 171 / 14%), 0px 1px 5px 0px rgb(145 158 171 / 12%);
    box-shadow: 0 8px 16px 0 rgb(121 102 33 / 24%);
    margin-right: 15px;
}

#lookup_field input {
    padding: 7px 11px !important;
    font-size: 16px;
    font-size: 16px;
    line-height: 1.5;
    border-radius: 8px;
    border-inline-end-width: 1px;
    box-sizing: border-box;
    margin: 0;
    border-radius: 3px !important;
    font-size: 14px;
    line-height: 1.5714285714285714;
    list-style: none;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    position: relative;
    display: inline-block;
    width: 100%;
    min-width: 0;
    background-color: #ffffff;
    background-image: none;
    border-width: 1px;
    border-style: solid;
    border-radius: 6px;
    transition: all 0.2s;
}

.idpc-select {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.88);
    font-size: 16px;
    line-height: 1.5714285714285714;
    list-style: none;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    display: flex;
    width: 100%;
    height: 40px;
    padding: 0 11px;
    border-radius: 3px;
}


.antd-font {
    font-family: Public Sans, sans-serif !important;
}

.input-title {
    margin: 0px 0px 8px;
    font-weight: 600;
    line-height: 1.57143;
    font-size: 0.875rem;
    font-family: "Public Sans", sans-serif;
    color: rgb(99, 115, 129);
}

.confirm-reset {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

/* End Post code CSS */

/* .ant-input:focus,
.ant-input:hover,
.ant-select-selector:focus,
.ant-select-selector:hover,
.ant-select-selector:active,
.ant-picker:hover,
.ant-picker:active,
.ant-select:focus,
.ant-select:hover {
    border-color: #9B7E4A !important;
    outline: 0 !important;
    -webkit-box-shadow: 0 0 0 2px #9b7f4a31 !important;
    box-shadow: 0 0 0 2px #9b7f4a3d !important;
} */

.MuiDialog-container .MuiPaper-elevation {
    border-radius: 8px !important;
    min-width: 500px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.css-1m9bonx-MuiBackdrop-root-MuiDialog-backdrop {
    background: transparent !important;
    backdrop-filter: blur(3px);
}

/* new */
.mb-20 {
    margin-bottom: 20px !important;
}

.css-ei05vv {
    padding-bottom: 0px !important;
}

iframe {
    z-index: -9999 !important;
}

.free-trial-modal {
    margin-left: 0px !important;
}

.free-trial-modal .ant-modal-content {
    border-radius: 0px !important;
    padding: 0 !important;
}

.landingVideo {
    z-index: 1 !important;
    margin-bottom: 100px;
}

.center {
    text-align: center !important;
}

.ant-pagination-options .ant-select-selector,
.ant-pagination-options .ant-select-arrow {
    display: none !important;
}

.deletehistory {
    display: none;
    cursor: pointer;
}

.chatbody:hover .deletehistory {
    display: flex !important;
}

.nomessage {
    height: 200px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.middle-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.css-1nfxy14-MuiTypography-root-MuiLink-root {
    font-weight: 400 !important;
}

#logo1 {
    width: 75px;
}

@media only screen and (max-width: 600px) {
    #logo1 {
        width: 65px;
    }
}

.css-9xa8rz-MuiGrid-root>.MuiGrid-item {
    align-items: center;
}

.analyze .ant-upload-drag {
    border: none !important;
    background: #fff !important;
}

.css-1nfxy14-MuiTypography-root-MuiLink-root,
.css-zs38a0-MuiTypography-root-MuiLink-root {
    font-size: 0.975rem !important;
}

.compare_content {
    min-width: 400px;
    white-space: pre-line !important;
}

.uppercase {
    text-transform: uppercase;
}

.menuMembership {
    color: #212B36;
    display: flex;
    justify-content: space-between;
    align-content: center;
    flex-wrap: wrap;
}

.back-button {
    padding: 0px !important;
    float: right !important;
}

/* login */
.login-logo {
    width: 50px;
    margin: auto;
    padding-bottom: 30px;
}

/* login */

:where(.css-dev-only-do-not-override-i0102m).ant-pagination .ant-pagination-item-active {
    border-color: #00a2ff;
}

:where(.css-dev-only-do-not-override-i0102m).ant-pagination .ant-pagination-item-active a {
    color: #00a2ff;
}

.react-diff-1igno8l-gutter,
.react-diff-1p2tkx4-marker {
    display: none !important;
}

.tawk-icon-right img {
    display: none !important;
}

.blog-content p {
    margin-bottom: 10px !important;
}

/* blog */
.css-lpbprb::before {
    background-color: transparent !important
}

.css-1qy8zjg::after {
    background-color: transparent !important
}



/* width */
.scroll1 {
    white-space: pre-line;
}

.scroll1::-webkit-scrollbar {
    width: 10px;
    border-radius: 5px;
}

/* Track */
.scroll1::-webkit-scrollbar-track {
    background: #f5f5f5;
    border-radius: 5px;
}

/* Handle */
.scroll1::-webkit-scrollbar-thumb {
    background: #b6b6b696;
    border-radius: 5px;
    cursor: grab;
}

/* Handle on hover */
.scroll1::-webkit-scrollbar-thumb:hover {
    background: #707070;
}

.scroll1::-webkit-scrollbar-thumb:active {
    cursor: grabbing;
}

.diff-item {
    /* border-right: 1px #414141 solid; */
}

.legend-mouseover-inactive {
    opacity: 1 !important;
    font-size: 1.2rem !important;
}

.feedback-banner {
    min-height: 300px;
    background-size: cover !important;
    background-position: center !important;
    display: flex;
    align-items: end;
}

.css-d8b3ns-MuiPaper-root-MuiCard-root {
    overflow: visible !important;
}

/* .css-1lm0nes-MuiGrid-root .MuiGrid-item:nth-child(3) {
    padding-top: 0px !important
} */

.most-popular {
    font-size: 15px;
    text-align: center;
    position: absolute;
    display: flex;
    justify-content: center;
    top: -15px;
    background-color: #211c6e;
    margin: auto;
    border-radius: 20px;
    color: #fff;
    padding: 8px 23px;
}

.landing-icon {
    width: 26px;
    height: 26px;
    margin: auto;
    margin-bottom: 10px;
}

.preline {
    white-space: pre-line;
}

.mh-250 {
    height: calc(100vh - 250px);
    min-height: calc(100vh - 250px);
    overflow-y: scroll;
}

.css-1ae7ola::after {
    background-color: transparent !important;
}

.compare-one {
    display: flex;
    flex-flow: column;
    justify-content: space-between;
}

.pieChat {
    display: flex;
    justify-content: center !important;
}

.css-652zax-MuiTypography-root,
.css-euopb5-MuiTypography-root {
    font-size: 2.8rem !important;
}

@media only screen and (max-width: 600px) {
    .mh-250 {
        min-height: auto !important;
        height: auto !important;
    }

    .compare-one {
        padding-bottom: 20px;
        margin-bottom: 20px;
    }

    .xs-hide {
        display: none;
    }
}

.fileuploading-btn .ant-upload-btn {
    padding: 0px !important;
}

.fileuploading-btn .ant-upload-drag {
    height: 24px;
}

.fileuploading-btn .MuiSvgIcon-root {
    fill: #fff !important;
}

.fileuploading-btn .ant-upload-list {
    display: none;
}

.fileuploading-btn .ant-upload-drag {
    border: none !important;
}

.generating-body {
    width: 100vw;
}

.generating-list {
    /* background: #F9F9F9; */
    padding: 8px 12px;
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100vh;
    border-right: 1px solid #e2e2e288;
}

.generating-list .one {
    width: 250px;
    font-size: .875rem;
    line-height: 1.25rem;
    overflow: hidden;
    padding-right: 0.1rem;
}

.generating-list .one .sub {
    color: #333;
    text-decoration: none;
}

.generating-list .one .item {
    width: 700px;
    padding: .5rem;
}

.generating-list .one:hover {
    background: #ececec;
    border-radius: 5px;
    cursor: pointer;
}

.chat-wrapper1 {
    /* padding-left: 274px;
    width: calc(100vw - 274px); */
}

.generating-list .tools {
    display: flex;
    justify-content: flex-end;
}

.generating-list .tools .item {
    padding-left: .5rem;
    padding-right: .5rem;
    border-radius: .5rem;
    color: #5d5d5d;
    height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.generating-list .tools .item:hover {
    background: #E7E7E7;
}

.css-k8t0vn-MuiFormControl-root-MuiTextField-root .MuiOutlinedInput-root {
    border: 1px solid #e2e2e2;
    box-shadow: 0 9px 9px 0px rgba(0,0,0,.01),0 2px 5px 0px rgba(0,0,0,.06);
}